import { Icon } from '../icon/icon'
import { getIconName } from '../icon/icon.helper'
import { Spacer } from '../spacer/spacer'
import Text from '../text/text'
import { cn } from '~/utils/misc'

export default function AuthContainer({
	children,
	padding = true,
}: {
	children: React.ReactNode
	padding?: boolean
}) {
	return (
		<div
			className={cn(
				'flex min-h-full flex-col justify-center',
				padding && 'py-20',
			)}
		>
			<div className="mx-auto w-full max-w-md px-8 md:px-0">{children}</div>
		</div>
	)
}

export function AuthContainerHeader({
	title,
	text,
	icon,
}: {
	title: React.ReactNode
	text?: React.ReactNode
	icon?: string
}) {
	return (
		<div className="text-center">
			{icon && (
				<Icon
					name={getIconName(icon)}
					className="mb-4 text-secondary"
					size="xxxxl"
				/>
			)}
			<Text as="h1" className="!text-4xl">
				{title}
			</Text>
			{text && (
				<Text className="mt-3" color="light">
					{text}
				</Text>
			)}
			<Spacer size="3xs" />
		</div>
	)
}
